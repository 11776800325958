import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Title,
  FullHeightBox,
  FullHeightImage,
  StyledTextField,
  StyledButton,
  ForgetPassword,
} from "./styles";

export default function Login() {
  const isMobile = useMediaQuery("(max-width: 900px)");
  return (
    <FullHeightBox>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={8}
          md={7}
          lg={7}
          xl={6}
          container
          sx={{ maxHeight: "100vh", overflow: "hidden", position: "relative" }}
        >
          <FullHeightImage
            src={
              !isMobile ? "/images/bg-login.png" : "/images/bg-login-mobile.png"
            }
            alt="img"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={6} textAlign="center" >
          <Grid container display='flex' justifyContent='center'>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              pt={{ xs: 3.5, sm: 2, md: 5, lg: 5, xl: 5 }}
              textAlign="center"
            >
              <img
                src="/images/logo-white.png"
                alt="logo"
                width={128}
                height={68}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              py={{ xs: 4, sm: 4, md: 10, lg: 10, xl: 10 }}
              textAlign="center"
            >
              <Title>Faça seu login</Title>
            </Grid>
            <Grid item xs={11} sm={10} md={10} lg={8} xl={6.5}>
              <StyledTextField placeholder="CPF" />
            </Grid>
            <Grid item xs={11} sm={10} md={10} lg={8} xl={6.5} pt={2}>
              <StyledTextField placeholder="Senha" type="password" />
            </Grid>
            <Grid item xs={11} sm={10} md={10} lg={8} xl={6.5} pt={2}>
             <StyledButton>Entrar</StyledButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              pt={{ xs: 6, sm: 5, md: 10, lg: 10, xl: 10 }}
              pb={{ xs: 3, sm: 2, md: 3, lg: 3, xl: 3 }}
              textAlign="center"
            >
              <ForgetPassword>Esqueci a senha</ForgetPassword>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullHeightBox>
  );
}
